module.exports = {
    scrollTo: scrollTo,
    addOnScroll: addOnScroll,
    removeOnScroll: removeOnScroll,
    scrollToEl: scrollToEl,
    addOnScrollTopCrosses: addOnScrollTopCrosses,
};

var $body;
var $window;
var onScrolling   = false;
var lastScrollPos = 0;
var lastDelta     = 0;
var callbacks     = [];
$(function () {

    $body         = $('html, body');
    $window       = $(window);
    lastScrollPos = $window.scrollTop();
    window.addEventListener('scroll', _onScroll);
});


function addOnScroll(callback) {
    callbacks.push(callback);
}

function removeOnScroll(callback) {
    var index = callbacks.indexOf(callback);
    if (index !== -1) {
        callbacks.splice(index, 1);
    }
}

function _onScroll(e) {

    var delta     = lastScrollPos - $window.scrollTop();
    lastScrollPos = $window.scrollTop();
    lastDelta     = delta;
    if (!onScrolling) {
        window.requestAnimationFrame(function () {
            callbacks.forEach(function (cb) {
                cb(lastScrollPos, delta);
            });
            onScrolling = false;
        });
    }
    onScrolling = true;
}

function addOnScrollTopCrosses(getCrossPos, onCrossDown, onCrossUp, triggerNow) {

    var crossPos  = getCrossPos();
    var prevAbove = crossPos < lastScrollPos;

    if(triggerNow){
        onScroll(lastScrollPos);
    }

    addOnScroll(onScroll);

    function onScroll(pos) {

        var crossPos = getCrossPos();
        var above    = crossPos < pos;

        if (above === prevAbove) {
            return;
        }

        if (above) {
            console.log('down');
            if (onCrossDown) {
                onCrossDown();
            }
        }
        else {
            console.log('up');
            if (onCrossUp) {
                onCrossUp();
            }

        }

        prevAbove = above;
    }

}

function scrollToEl($el) {
    var offset = $el.offset();

    if (!offset) {
        return;
    }
    return scrollTo(offset.top);
}

function scrollTo(pos) {
    $body
        .stop()
        .animate({
                scrollTop: pos
            },
            700,
            'easeInOutCubic'
        );
}

function noop() {
}