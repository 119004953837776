module.exports = make;

function make(settings) {
    settings = settings || {};

    var map            = settings.map;
    var layerStyle     = settings.layerStyle;
    var cameras        = settings.cameras;
    var bounds         = settings.bounds;

    return {
        toStart: toStart,
        toAll: toAll,
        toClients: toClients,
        toProperties: toProperties,
        toBaltimore: toBaltimore,
        toDc: toDc,
    };

    function toStart(settings) {

        var camSettings = $.extend(
            {},
            cameras.defaults,
            cameras.start,
            settings
        );

        map.flyTo(camSettings);
    }

    function toAll(settings) {

        var camSettings = $.extend(
            {},
            cameras.defaults,
            cameras.all,
            settings
        );

        map.fitBounds(bounds.all, camSettings);

        layerStyle.setClientsOpacity(1);
        layerStyle.setPropertiesOpacity(1);
        layerStyle.setOfficeOpacity(1);
    }

    function toClients(settings) {

        var camSettings = $.extend(
            {},
            cameras.defaults,
            cameras.clients,
            settings
        );

        map.fitBounds(bounds.clients, camSettings);

        layerStyle.setClientsOpacity(1);
        layerStyle.setPropertiesOpacity(0.25);
        layerStyle.setOfficeOpacity(0.25);
    }

    function toProperties(settings) {

        var camSettings = $.extend(
            {},
            cameras.defaults,
            cameras.properties,
            settings
        );

        map.fitBounds(bounds.properties, camSettings);

        layerStyle.setClientsOpacity(0.25);
        layerStyle.setPropertiesOpacity(1);
        layerStyle.setOfficeOpacity(0.25);
    }

    function toBaltimore(settings) {

        var camSettings = $.extend(
            {},
            cameras.defaults,
            cameras.officeBaltimore,
            settings
        );

        map.flyTo(camSettings);

        layerStyle.setClientsOpacity(1);
        layerStyle.setPropertiesOpacity(1);
        layerStyle.setOfficeOpacity(1);

    }

    function toDc(settings) {

        var camSettings = $.extend(
            {},
            cameras.defaults,
            cameras.officeDc,
            settings
        );

        map.flyTo(camSettings);

        layerStyle.setClientsOpacity(1);
        layerStyle.setPropertiesOpacity(1);
        layerStyle.setOfficeOpacity(1);
    }
}