module.exports = windowSize;

function windowSize() {

    var win = window;
    var doc = document;
    var e   = doc.documentElement;
    var g   = doc.getElementsByTagName('body')[0];
    var w   = win.innerWidth || e.clientWidth || g.clientWidth;
    var h   = win.innerHeight || e.clientHeight || g.clientHeight;

    return {
        w: w,
        h: h,
    }
}