var windowSize = require('./window-size');

var callbacks  = [],
    running    = false;

var last = [];

// fired on resize event
function resize() {

    if (!running) {
        running = true;
        raf();
    }
}

function raf() {
    if (window.requestAnimationFrame) {
        window.requestAnimationFrame(runCallbacks);
    } else {
        setTimeout(runCallbacks, 66);
    }
}
// run the actual callbacks
function runCallbacks() {

    var size = windowSize();
    callbacks.forEach(function(callback) {
        callback(size.w, size.h);
    });

    last.forEach(function(callback) {
        callback(size.w, size.h);
    });

    running = false;
}

window.addEventListener('resize', resize);

module.exports = {
    // public method to add additional callback
    add: function(callback) {
        callbacks.push(callback);
    },
    addLast: function(callback) {
        last.push(callback);
    },
    trigger: function() {
        raf();
    },
}