var helpers = require('../../lib/map-helpers');

var locationsToFeatures   = helpers.locationsToFeatures;
var makeClusterLayerSizes = helpers.makeClusterLayerSizes;
var makeClusterLayers     = helpers.makeClusterLayers;

module.exports = populate;

function populate(map, data) {

    var properties = data.properties;
    var clients    = data.clients;
    var offices    = data.offices;

    var purple = '#2a0d54';
    var blue   = '#0E436A';
    var orange = '#F7901C';

    // merge clients + properties

    var merged = [].concat(properties, clients);

    //map.addSource('clients', {
    //    'type': 'geojson',
    //    'data': locationsToFeatures(clients),
    //    cluster: true,
    //    clusterMaxZoom: 14, // Max zoom to cluster points on
    //    clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    //});

    map.addSource('properties', {
        'type': 'geojson',
        'data': locationsToFeatures(merged),
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    });

    var officeData = locationsToFeatures(offices);
    map.addSource('offices', {
        'type': 'geojson',
        'data': officeData
    });

    officeData.features.forEach(function (marker) {
        // create a DOM element for the marker
        var el                   = document.createElement('div');
        el.className             = 'home-map-marker';
        el.style.backgroundImage = 'url(/wp-content/themes/segall/img/map/segall-pin.svg)';

        var width  = 100;
        var height = 100;

        var hOffset = -width * 0.5;
        var vOffset = -height;

        el.style.width  = width + 'px';
        el.style.height = height + 'px';

        // add marker to map
        var marker = new mapboxgl.Marker(el, {offset: [hOffset, vOffset]})
            .setLngLat(marker.geometry.coordinates)
            .addTo(map);

    });

    //map.addLayer({
    //    'id': 'clients-layer',
    //    'source': 'clients',
    //    // 'type': 'symbol',
    //    // 'layout': {
    //    //     'icon-image': 'pin-blue',
    //    //     'icon-padding': 0,
    //    //     'icon-allow-overlap': true,
    //    // },
    //    // paint: {}
    //    type: 'circle',
    //    paint: {
    //        'circle-color': purple,
    //        'circle-opacity': 1,
    //    }
    //});
    //
    //map.addLayer({
    //    'id': 'properties-layer',
    //    'source': 'properties',
    //    // 'type': 'symbol',
    //    // 'layout': {
    //    //     'icon-image': 'pin-orange',
    //    //     'icon-padding': 0,
    //    //     'icon-allow-overlap': true,
    //    // },
    //    // paint: {}
    //    type: 'circle',
    //    paint: {
    //        'circle-color': orange,
    //        'circle-opacity': 1,
    //    }
    //});
    //
    //map.addLayer({
    //    'id': 'offices-layer',
    //    'source': 'offices',
    //    'type': 'symbol',
    //    'layout': {
    //        'icon-image': 'pin-orange',
    //        'icon-padding': 0,
    //        'icon-allow-overlap': true,
    //    },
    //    paint: {}
    //});

    //function noop(){}
    //
    //return {
    //    setClientsPointStyle: noop,
    //    setPropertiesPointStyle: noop,
    //    setPropertiesTextStyle: noop,
    //    setClientsTextStyle: noop,
    //    setOfficePointStyle: noop,
    //};

    var clusterLayerSizes = makeClusterLayerSizes({
        maxCount: 100,
        minRadius: 10,
        maxRadius: 18,
        stepSize: 10,
    });

    //map.addLayer({
    //    'id': 'offices-layer',
    //    'source': 'offices',
    //    'type': 'circle',
    //    paint: {
    //        'circle-color': blue,
    //        'circle-opacity': 1,
    //        'circle-radius': 10,
    //    }
    //});


    map.addLayer({
        'id': 'offices-layer',
        'source': 'offices',
        'type': 'symbol',
        'layout': {
            'icon-image': 'pin-purple',
            'icon-padding': 0,
            'icon-allow-overlap': true,
        },
        paint: {}
    });


    var propertiesLayers = makeClusterLayers({
        id: 'properties',
        map: map,
        source: 'properties',
        circlePaint: {
            "circle-color": purple,
            'circle-opacity': 1,

        },
        unclusteredCirclePaint: {
            "circle-color": purple,
            "circle-radius": 10,
            'circle-opacity': 1,
        },
        textPaint: {
            'text-color': '#fff'
        },
        clusterLayerSizes: clusterLayerSizes,
    });

    //var clientsLayers = makeClusterLayers({
    //    id: 'clients',
    //    source: 'clients',
    //    circlePaint: {
    //        "circle-color": purple,
    //        'circle-opacity': 1,
    //
    //    },
    //    unclusteredCirclePaint: {
    //        "circle-color": purple,
    //        "circle-radius": 10
    //    },
    //    textPaint: {
    //        'text-color': '#ffffff'
    //    },
    //    clusterLayerSizes: clusterLayerSizes,
    //});

    function setClientsPointStyle(key, val) {
        //clientsLayers.pointLayers.forEach(function (layerId) {
        //    map.setPaintProperty(layerId, key, val);
        //});
    }

    function setPropertiesPointStyle(key, val) {
        propertiesLayers.pointLayers.forEach(function (layerId) {
            map.setPaintProperty(layerId, key, val);
        });
    }

    function setPropertiesTextStyle(key, val) {
        propertiesLayers.textLayers.forEach(function (layerId) {
            map.setPaintProperty(layerId, key, val);
        });
    }

    function setClientsTextStyle(key, val) {
        propertiesLayers.textLayers.forEach(function (layerId) {
            map.setPaintProperty(layerId, key, val);
        });
    }

    function setOfficePointStyle(key, val) {
        //map.setPaintProperty('offices-layer', key, val);
    }

    function setOfficeOpacity(opacity) {
        //setOfficePointStyle('circle-opacity', opacity);
    }

    function setClientsOpacity(opacity) {
        setClientsPointStyle('circle-opacity', opacity);
        setClientsTextStyle('text-opacity', opacity);
    }

    function setPropertiesOpacity(opacity) {
        setPropertiesPointStyle('circle-opacity', opacity);
        setPropertiesTextStyle('text-opacity', opacity);
    }

    return {
        setClientsPointStyle: setClientsPointStyle,
        setPropertiesPointStyle: setPropertiesPointStyle,
        setPropertiesTextStyle: setPropertiesTextStyle,
        setClientsTextStyle: setClientsTextStyle,
        setOfficePointStyle: setOfficePointStyle,
        setOfficeOpacity: setOfficeOpacity,
        setClientsOpacity: setClientsOpacity,
        setPropertiesOpacity: setPropertiesOpacity,
    };

}
