require('./home/map');

var sr         = require('./lib/scroll-reveal');
var onResize   = require('./lib/resize');
var scroll     = require('./lib/scroll');
var windowSize = require('./lib/window-size');
var isIE       = require('./lib/is-ie')();
var fullpage   = require('./lib/slides-fullpage');

var addOnScroll    = scroll.addOnScroll;
var removeOnScroll = scroll.removeOnScroll;

$(function () {

    if (isIE) {
        $('body').addClass('is-ie');
    }

    addOnScroll(fullpage.onScroll);

    initIntroVideo();
    initBrandVideo();

    onResize.add(fullpage.resize);
    onResize.trigger();

    var $btnArrowDown = $('.home-arrow-down');
    $btnArrowDown.on('click', function (e) {
        e.preventDefault();
        $.fn.fullpage.moveSectionDown();
    });

    fullpage.vent.once('autoScrollingChange', function (isOn) {
        if ($btnArrowDown) {
            if (isOn) {
                $btnArrowDown.show();
            } else {
                $btnArrowDown.hide();
            }
        }
    });

    fullpage.vent.on('from_slide', function ($slideFrom, $slideTo) {
        var fromId = $slideFrom.attr('id');
        var toId   = $slideTo.attr('id');
        if (fromId == 'slide-map' && !toId) {
            $btnArrowDown.hide();
        }
    });

    fullpage.vent.on('to_slide', function ($slideFrom, $slideTo) {
        var fromId = $slideFrom.attr('id');
        var toId   = $slideTo.attr('id');
        if (toId == 'slide-map' && !fromId) {
            $btnArrowDown.show();
        }
    });
});


function initIntroVideo() {

    var $video = $('.intro-bg-video');

    if (!$video.length) {
        return;
    }
    var video = $video[0];


    fullpage.vent.on('to_slide', function ($slideFrom, $slideTo) {
        if ($slideTo.attr('id') == 'slide-0') {
            video.play();
        }
    });

    fullpage.vent.on('from_slide', function ($slideFrom, $slideTo) {
        if ($slideTo.attr('id') == 'slide-0') {
            video.pause();
        }
    });

}

function initBrandVideo() {

    var $slideVideo      = $('#slide-video');
    var $teaserContainer = $('.video-teaser');
    var $controlOverlay  = $('.control-overlay');
    var $teaser          = $teaserContainer.find('video');
    var $videoContainer  = $('.video-main');
    var $video           = $videoContainer.find('video');

    if (!$video.length || !$teaser.length) {
        return;
    }

    var main   = $video[0];
    var teaser = $teaser[0];

    var mainVid    = makeVid($video);
    var teaserVid  = makeVid($teaser);
    var teaserMode = true;

    reset();
    teaserVid.load();


    fullpage.vent.on('to_slide', function ($slideFrom, $slideTo) {
        if ($slideTo.attr('id') == 'slide-video') {
            if (teaserMode) {
                teaser.play();
            } else {
                main.play();
            }
        }

    });

    $controlOverlay.on('click', function () {
        playVideo();
    });

    $video.on('click', function () {
        if (main.paused) {
            main.play();
        } else {
            main.pause();
        }
    });

    main.addEventListener('ended', function () {
        $controlOverlay.show();

        // reset if slide away
        fullpage.vent.once('to_slide', function () {
            mainVid.rewind();
            reset();
        });

    }, false);

    function reset() {
        $teaserContainer.show();
        $controlOverlay.show();
        $videoContainer.hide();
        teaserMode = true;
    }

    function playVideo() {
        $teaserContainer.hide();
        $controlOverlay.hide();
        $videoContainer.show();
        teaser.pause();

        mainVid.load();
        main.play();

        teaserMode = false;
    }

    function makeVid($el) {

        var loaded = false;
        var video  = $el[0];

        return {
            load: load,
            rewind: rewind,
        };

        function load() {
            if (loaded) {
                return;
            }
            $el.find('source').each(function () {
                var $this      = $(this);
                var sourceFile = $this.data('src');
                $this.attr('src', sourceFile);
                $this.data('src', '');
                video.load();
            });
        }

        function rewind() {
            video.pause();
            video.currentTime = 0;
        }

    }
}