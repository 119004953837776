var segallPosBaltimore = {
    lat: 39.284188,
    lng: -76.598056,
};

var segallPosDc = {
    lat: 38.894068,
    lng: -77.077216,
    //lat: 38.8941606,
    //lng: -77.0791661,
};

var properties = window.home_map_data.properties;
var clients    = window.home_map_data.clients;
var offices = [segallPosBaltimore, segallPosDc];


properties = properties.map(convert);
clients    = clients.map(convert);
offices    = offices.map(convert);


var all = [].concat(properties, clients, offices);

var bounds = {
    all: new mapboxgl.LngLatBounds(),
    properties: new mapboxgl.LngLatBounds(),
    clients: new mapboxgl.LngLatBounds(),
};

all.forEach(function (item) {
    bounds.all.extend(item);
});

properties.forEach(function (item) {
    bounds.properties.extend(item);
});

clients.forEach(function (item) {
    bounds.clients.extend(item);
});


module.exports = {
    properties: properties,
    clients: clients,
    offices: offices,
    officeBaltimore: segallPosBaltimore,
    officeDc: segallPosDc,
    all: all,
    bounds: bounds
};

function convert(item) {
    return [
        item.lng,
        item.lat
    ];
}
