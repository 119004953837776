var scroll      = require('../lib/scroll');
var addOnScroll = scroll.addOnScroll;
var onResize    = require('../lib/resize');
var fullpage    = require('../lib/slides-fullpage');


$(window).on('load', function () {
    mapboxgl.accessToken = 'pk.eyJ1Ijoic3BhcmstYW5kLWFzdGVyIiwiYSI6ImNqM3E2ZWd3dzAwd3AycWxqdHF0bmdycXYifQ.k6dyMCh8jd6aXexneVQDfQ';

    var populateMap = require('./map/populate');
    var mapData     = require('./map/data');
    var makeCameras = require('./map/camera');

    var $map          = $('#map');
    var $mapContainer = $('.home-map-container');

    var $mapBar      = $mapContainer.find('.home-map-bar');
    var $mapHeadings = $mapContainer.find('.slide-map-heading');
    var $mapHeading1 = $mapContainer.find('.slide-map-heading-1');
    var $mapHeading2 = $mapContainer.find('.slide-map-heading-2');
    var $mapHeading3 = $mapContainer.find('.slide-map-heading-3');
    var $mapHeading4 = $mapContainer.find('.slide-map-heading-4');
    var $btnPrev     = $mapContainer.find('.left');
    var $btnNext     = $mapContainer.find('.right');

    var officeBaltimorePos = mapData.officeBaltimore;
    var officeDcPos        = mapData.officeDc;
    var bounds             = mapData.bounds;

    var zoomClasses = [
        'zoom-1',
        'zoom-2',
        'zoom-3',
        'zoom-4',
        'zoom-5',
        'zoom-6',
        'zoom-7',
        'zoom-8',
        'zoom-9',
        'zoom-10',
        'zoom-11',
        'zoom-12',
        'zoom-13',
        'zoom-14',
        'zoom-15',
        'zoom-16',
        'zoom-17',
    ];

    var cameras = {

        defaults: {
            padding: 50,
            pitch: 0,
            bearing: 0,
            duration: 2500,
        },
        start: {
            center: [-79.3556540655101, 39.328990750369826],
            zoom: 2,
            pitch: 40,
            bearing: 0,
        },
        officeDc: {
            center: officeDcPos,
            zoom: 17,
            pitch: 50,
            bearing: -17.6,
        },
        officeBaltimore: {
            center: officeBaltimorePos,
            zoom: 17,
            pitch: 50,
            bearing: -17.6,
        }
    };

    var mapSettings = $.extend({
        container: 'map',
        style: 'mapbox://styles/mapbox/light-v9',
        scrollZoom: false,
        dragPan: false,
        dragRotate: false,
        attributionControl: false,
    }, cameras.start);


    $mapHeadings.hide();

    var map                = new mapboxgl.Map(mapSettings);
    var attributionControl = new mapboxgl.AttributionControl({position: 'top-left'});

    map.addControl(attributionControl);
    window.map = map;

    onResize.add(function (width, height) {
        var mapHeight = height - 80;
        if ($('body').hasClass('admin-bar')) {
            mapHeight -= 32;
        }
        $map.height(mapHeight);
        map.resize();
    });

    onResize.trigger();

    map.on('load', function () {

        map.addLayer({
            'id': '3d-buildings',
            'source': 'composite',
            'source-layer': 'building',
            'filter': ['==', 'extrude', 'true'],
            'type': 'fill-extrusion',
            'minzoom': 15,
            'paint': {
                'fill-extrusion-color': '#aaa',
                'fill-extrusion-height': {
                    'type': 'identity',
                    'property': 'height'
                },
                'fill-extrusion-base': {
                    'type': 'identity',
                    'property': 'min_height'
                },
                'fill-extrusion-opacity': .6
            }
        });

        var layerStyle = populateMap(map, mapData);

        var cameraSettings = {
            map: map,
            layerStyle: layerStyle,
            cameras: cameras,
            bounds: bounds,
        };

        var camera = makeCameras(cameraSettings);


        map.on('zoom', onZoom);
        onZoom();

        $mapHeadings
            .addClass('map-heading-fixed')
            .hide();

        $mapHeading1
            .removeClass('map-heading-fixed')
            .show();


        function headingIn($heading) {
            $heading
                .stop()
                .removeClass('map-heading-fixed')
                .fadeIn(1250);
        }

        function headingOut($heading) {
            $heading
                .stop()
                .addClass('map-heading-fixed')
                .fadeOut(1250);
        }

        var slides = MapSlides([
            {
                start: function () {

                    headingIn($mapHeading1);

                    camera.toStart({
                        duration: 1250
                    });

                    $btnPrev.hide();
                },
                end: function () {
                    headingOut($mapHeading1);

                    $btnPrev.show();
                }
            },
            {
                start: function () {

                    headingIn($mapHeading2);

                    camera.toAll({
                        duration: 1250
                    });
                },
                end: function () {
                    headingOut($mapHeading2);
                }
            },
            {
                start: function () {
                    headingIn($mapHeading3);

                    camera.toBaltimore({
                        duration: 1250
                    });
                },
                end: function () {
                    headingOut($mapHeading3);

                }
            },
            {
                start: function () {
                    headingIn($mapHeading4);


                    camera.toDc({
                        duration: 1250
                    });

                    $btnNext.hide();
                },
                end: function () {
                    headingOut($mapHeading4);

                    $btnNext.show();


                }
            },

        ]);

        $btnPrev.on('click', function (e) {
            e.preventDefault();
            slides.prev();
        });

        $btnNext.on('click', function (e) {
            e.preventDefault();
            slides.next();
        });

        slides.goto(0);

        fullpage.vent.on('from_slide', function ($slideFrom, $slideTo) {

            var fromMap = false;
            var toMap   = false;

            if ($slideFrom) {
                fromMap = $slideFrom.hasClass('slide-map');
            }

            if ($slideTo) {
                toMap = $slideTo.hasClass('slide-map');
            }

            if (fromMap) {
                $mapBar
                    .stop()
                    .delay(500)
                    .fadeOut();
            }

            if (toMap) {
                $mapBar
                    .stop()
                    .delay(500)
                    .fadeIn();
            }

        });

        var prevZoom;

        function onZoom() {
            var zoom = map.getZoom();

            zoom = Math.floor(zoom);
            if (zoom == prevZoom) {
                return;
            }

            $mapContainer.removeClass(zoomClasses.join(' '));
            $mapContainer.addClass('zoom-' + zoom);
            prevZoom = zoom;
        }

    });


})
;

function MapSlides(slides) {
    slides = slides || [];

    var currentIndex = 0;

    function prev() {
        goto(currentIndex - 1);
    }

    function next() {
        goto(currentIndex + 1);
    }

    function goto(toIndex) {

        var from = slides[currentIndex];
        var to   = slides[toIndex];

        if (!to) {
            return;
        }

        currentIndex = toIndex;

        if (from && from.end) {
            from.end();
        }

        if (to && to.start) {
            to.start();
        }
    }

    return {
        goto: goto,
        prev: prev,
        next: next,
    };


}