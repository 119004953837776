module.exports = {
    locationsToFeatures: locationsToFeatures,
    makeClusterLayerSizes: makeClusterLayerSizes,
    makeClusterLayers: makeClusterLayers,
};

function locationsToFeatures(locations) {
    var features = locations.map(locationToFeature);
    console.log('features', features);
    return {
        "type": "FeatureCollection",
        "features": features,
    };
}

function locationToFeature(pos) {
    var feature = {
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": pos
        },
        "properties": {}
    };

    return feature;
}

function makeClusterLayerSizes(settings) {

    var maxCount  = settings.maxCount;
    var minRadius = settings.minRadius;
    var maxRadius = settings.maxRadius;
    var stepSize  = settings.stepSize;

    function getRad(val) {
        var ratio = val / maxCount;
        var range = maxRadius - minRadius;
        return minRadius + range * ratio;
    }

    var clusterLayers = [];

    for (var i = 0; i < maxCount; i += stepSize) {
        var size  = i;
        var layer = {
            size: size,
            radius: Math.floor(getRad(size)),
        };
        clusterLayers.push(layer)
    }

    clusterLayers = clusterLayers.reverse();
    return clusterLayers;

}


function makeClusterLayers(settings) {
    var id                     = settings.id;
    var map                     = settings.map;
    var source                 = settings.source;
    var circlePaint            = settings.circlePaint;
    var unclusteredCirclePaint = settings.unclusteredCirclePaint;
    var clusterLayerSizes      = settings.clusterLayerSizes;
    var textPaint              = settings.textPaint;

    var layerSizes = [];

    var layerUnclusteredPoints = id + "-unclustered-points";
    var layerUnclusteredCount  = id + "-unclustered-count";
    var layerClusterCount      = id + "cluster-count";

    map.addLayer({
        "id": layerUnclusteredPoints,
        "type": "circle",
        "source": source,
        "filter": ["!has", "point_count"],
        "paint": unclusteredCirclePaint,
    });

    map.addLayer({
        "id": layerUnclusteredCount,
        "type": "symbol",
        "source": source,
        "layout": {
            "text-field": "1",
            "text-font": [
                "DIN Offc Pro Medium",
                "Arial Unicode MS Bold"
            ],
            "text-size": 12,
        },
        paint: textPaint
    });

    clusterLayerSizes.forEach(function (item, i) {

        var prev = clusterLayerSizes[i - 1];

        var filter = [
            "all",
            [">=", "point_count", item.size],
        ];

        if (prev) {
            filter = [
                "all",
                [">=", "point_count", item.size],
                ["<", "point_count", prev.size]
            ];
        }

        var layerCirclePaint = $.extend({}, circlePaint, {"circle-radius": item.radius});

        var layer = id + "cluster-" + i;
        layerSizes.push(layer);

        map.addLayer({
            "id": layer,
            "type": "circle",
            "source": source,
            "paint": layerCirclePaint,
            "filter": filter,
        });
    });

    map.addLayer({
        "id": layerClusterCount,
        "type": "symbol",
        "source": source,
        "layout": {
            "text-field": "{point_count}",
            "text-font": [
                "DIN Offc Pro Medium",
                "Arial Unicode MS Bold"
            ],
            "text-size": 12,
        },
        paint: textPaint
    });


    return {
        unclusteredPoints: layerUnclusteredPoints,
        unclusteredCount: layerUnclusteredCount,
        clusterCount: layerClusterCount,
        layerSizes: layerSizes,
        pointLayers: [].concat(layerSizes, layerUnclusteredPoints),
        textLayers: [].concat(layerUnclusteredCount, layerClusterCount)
    }

}