require('./jquery.fullpage.js');
var ee = require('event-emitter');

var vent = ee({});

module.exports = {
    resize: resize,
    onScroll: onScroll,
    vent: vent,
};

var currentDirection;
var currentNextIndex;
var currentIndex;
var active;
var $container;
var $slides;
var $lastSlide;
var lastSlideTop;
var lastSlideIndex;
var $body;
var threshold;
var $btnArrowDown;
$(function () {
    $btnArrowDown  = $('.home-arrow-down')
    $container     = $('.match-slides');
    $slides        = $container.children('.slide-z');
    $lastSlide     = $slides.last();
    lastSlideTop   = $lastSlide.offset().top;
    lastSlideIndex = $slides.length;
    $body          = $('html, body');
    threshold      = lastSlideTop - 120;

    var autoScrolling = $body.scrollTop() < threshold;
    init(autoScrolling);
});

function on() {
    $.fn.fullpage.setAutoScrolling(true);
    active = true;
    vent.emit('autoScrollingChange', true);
}

function off() {
    $.fn.fullpage.setAutoScrolling(false);
    active = false;
    vent.emit('autoScrollingChange', false);
}

function onScroll(pos, delta) {

    var scrollingUp = delta > 0;
    var threshold   = lastSlideTop + 100;

    if (scrollingUp) {
        if (pos < threshold && !active) {
            on();
            //$.fn.fullpage.moveTo(lastSlideIndex);
        }
    }
}

function resize() {
    if (!$lastSlide) {
        return;
    }
    lastSlideTop = $lastSlide.offset().top;
}

function init(autoScrolling) {

    $container.fullpage({

        //navigation
        menu: false,
        anchors: [],
        lockAnchors: false,
        navigation: false,
        navigationPosition: 'right',
        navigationTooltips: [],
        showActiveTooltip: false,
        slidesNavigation: false,
        slidesNavPosition: 'bottom',
        scrollBar: true,

        css3: false,
        scrollingSpeed: 700,
        autoScrolling: autoScrolling,
        // autoScrolling: false,
        fitToSection: false,
        //fitToSectionDelay: 1000,
        //easing: 'easeInOutCubic',
        //easingcss3: 'ease',
        //loopBottom: false,
        //loopTop: false,
        //loopHorizontal: true,
        //continuousVertical: false,
        //continuousHorizontal: false,
        //scrollHorizontally: false,
        //interlockedSlides: false,
        //dragAndMove: false,
        //offsetSections: false,
        //resetSliders: false,
        //fadingEffect: false,
        //normalScrollElements: null,
        //scrollOverflow: false,
        //scrollOverflowReset: false,
        //// scrollOverflowHandler:             iscrollHandler,
        //scrollOverflowOptions: null,
        //touchSensitivity: 5,
        //normalScrollElementTouchThreshold: 5,
        //bigSectionsDestination: null,
        //
        ////Accessibility
        //keyboardScrolling: true,
        animateAnchor: false,
        //recordHistory: true,
        //
        ////design
        //controlArrows: true,
        //controlArrowColor: '#fff',
        verticalCentered: false,
        //paddingTop: 0,
        //paddingBottom: 0,
        //fixedElements: null,
        //responsiveWidth: 0,
        //responsiveHeight: 0,
        responsiveSlides: false,

        //Custom selectors
        sectionSelector: '.slide-z',
        slideSelector: '.zzzzz',

        //events
        afterLoad: function (anchorLink, index) {
            if (currentDirection === 'down' && currentNextIndex == lastSlideIndex) {
                off();
            }
            var $slide = $($slides[index - 1]);
            var $prev  = $($slides[currentIndex - 1]);


            vent.emit('to_slide', $prev, $slide);

            currentDirection = null;
            currentNextIndex = null;
        },
        onLeave: function (index, nextIndex, direction) {
            currentIndex     = index;
            currentDirection = direction;
            currentNextIndex = nextIndex;

            var $slide = $($slides[index - 1]);
            var $next  = $($slides[nextIndex - 1]);

            vent.emit('from_slide', $slide, $next);
        },

        afterRender: function () {
            //var height = $(window).height() * 0.5;
            //$('.slide-z')
            //    .css('height', height + 'px');

        },
        afterResize: null,
        afterReBuild: function () {
            vent.emit('after_rebuild');
        },
        afterSlideLoad: null,
        onSlideLeave: null,
        afterResponsive: null,
        lazyLoading: false
    });

}
